.nowrap{
  text-wrap: nowrap;
}
.saldo_poin{
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  border-radius: 10px;
  font-weight: 600;
  color: #FFF;
  font-size: 16px;
  background-color: #6A5FF3;
  background-position: bottom;
}
.poin_debit{
  color: #D92727;
}
.poin_debit div {
  background: #FFEBEB;
}
.poin_credit{
  color: #10BD41;
}
.poin_credit div {
  background: #DEFFE1;
}
.poin_zero{
  color: #868686;
}
.poin_debit, .poin_credit, .poin_zero{
  font-weight: 600;
}
.poin_credit, .poin_debit,
.poin_credit div, .poin_debit div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.poin_credit div, .poin_debit div {
  width: 25px;
  aspect-ratio: 1 / 1;
  margin: 0;
  border-radius: 50%;
  font-size: 14px;
}
.poin_credit i, .poin_debit i {
  font-size: 12px;
}